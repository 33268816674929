import JoeyLogo from "../../assets/joey_logo.png";
import HomeScreenButton from "./HomeButton";

function Home() {
	return (
		<>
			<div className="home-row">
				<div className="home-image-container">
					<img src={JoeyLogo} className="home-image" alt="logo" />
				</div>
				<div className="button-column">
					<h1 className="header">Joey Stempky 🎸</h1>
					<h2 className="subheader-text">💻 Software Engineer 💻</h2>
					<div className="button-container">
						<HomeScreenButton title={"Work"} path={"work"} />
						<HomeScreenButton title={"Education"} path={"education"} />
						<HomeScreenButton title={"Software"} path={"software"} />
						<HomeScreenButton title={"Services"} path={"services"} />
						<HomeScreenButton title={"2025 Books"} path={"books"} />
						<HomeScreenButton title={"About"} path={"about"} />
						<HomeScreenButton title={"Contact"} path={"contact"} />
					</div>
				</div>
			</div>
		</>
	);
}

export default Home;
