export const SoftwareConfig = {
	title: "2025 books",
	subtitle: "Books I've read and listened to in 2025",
	emoji: "📚",
	items: [
		{
			Title: "Nausea (1938)",
			Author: "Jean-Paul Sartre",
			Media: "Book",
		},
		{
			Title: "Chip War (2022)",
			Author: "Chris Miller",
			Media: "Audiobook",
		},
		{
			Title: "The Anxious Generation (2024)",
			Author: "Jonathan Haidt",
			Media: "Book",
		},
		{
			Title: "The Psychology of Money (2020)",
			Author: "Morgan Housel",
			Media: "Book",
		},
		{
			Title: "The Wine of Youth (1940)",
			Author: "John Fante",
			Media: "Book",
		},
		{
			Title: "The Go Programming Language (2016)",
			Author: "Alan A. A. Donovan, Brian W. Kernighan",
			Media: "Book",
		},
	],
};
