import { ServicesConfig } from "./ServicesConfig";
import "./Services.scss";

const Services = () => {
	const { title, subtitle, body } = ServicesConfig;
	return (
		<>
			<div className="services">
				<div className="hero">
					<h1>{title}</h1>
					<p>{subtitle}</p>
				</div>
			<div className="services-container"><p>{body}</p></div>
			</div>
		</>
	);
};

export default Services;
