import { SoftwareConfig } from "./BooksConfig";
import "./Books.scss";

const Books = () => {
	const { title, subtitle, items } = SoftwareConfig;
	return (
		<>
			<div className="books">
				<div className="hero">
						<h1>{title}</h1>
						<p>{subtitle}</p>
				</div>
				<div className="books-container">
					{items.map((book) => (
						<>
							<h2>{book.Title}</h2>
							<p>{book.Author}</p>
							<p>{book.Media}</p>
						</>
					))}
				</div>
			</div>
		</>
	);
};

export default Books;
