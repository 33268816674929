import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/navbar/NavBar";
import Home from "./screens/home/Home";
import Education from "./screens/education/Education";
import Work from "./screens/work/Work";
import About from "./screens/about/About";
import Contact from "./screens/Contact";
import Software from "./screens/software/Software";
import Screen from "./components/Screen";
import Books from "./screens/books/Books";

import Error from "./screens/Error";
import Services from "./screens/services/Services";

function App() {
	const withNavbar = (el: JSX.Element) => {
		return (
			<>
				<NavBar />
				{el}
			</>
		);
	};
	const withScreen = (el: JSX.Element) => {
		return <Screen>{el}</Screen>;
	};

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={withScreen(<Home />)} />
				<Route
					path="/education"
					element={withScreen(withNavbar(<Education />))}
				/>
				<Route path="/work" element={withScreen(withNavbar(<Work />))} />
				<Route
					path="/software"
					element={withScreen(withNavbar(<Software />))}
				/>
				<Route
					path="/services"
					element={withScreen(withNavbar(<Services />))}
				/>
				<Route path="/books" element={withScreen(withNavbar(<Books />))} />
				<Route path="/about" element={withScreen(withNavbar(<About />))} />
				<Route path="/contact" element={withScreen(withNavbar(<Contact />))} />
				<Route path="*" element={withScreen(withNavbar(<Error />))} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
